import React from "react"

import { ErrorResponse } from "../../../components/ErrorsVariants"
import LayoutOrder from "../../../components/Layout/LayoutOrder"

export default function ErrorTechnical() {
  return (
    <div className="error-pages">
      <LayoutOrder>
        <div className="error-pages-wrapper">
          <ErrorResponse variant="technical" />
        </div>
      </LayoutOrder>
    </div>
  )
}
