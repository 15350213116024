import React from "react"
import { navigate } from "gatsby"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { Wrapper } from "../Wrapper"
import { ErrorBtn } from "../ErrorBtn"
import type { Variant } from "./types"
import { items } from "./helper"

import * as styles from "./ErrorResponse.module.scss"
import { useFrame } from "../../../utils/useFrame"

type ErrorProps = {
  variant: Variant
}

const ErrorResponse = ({ variant }: ErrorProps) => {
  const { Icon, title, subTitle, btnText, eventName } = items[variant]

  const frameRef = useFrame()

  const navigateToStartUrl = () => {
    const firstURL = sessionStorage.getItem("firstURL")
    if (firstURL) {
      navigate(firstURL, { replace: true })
    } else {
      navigate("/frame/form-order-frame", { replace: true })
    }

    pushToDataLayer({
      event: "buttonClick",
      placement: "error_screen",
      name: eventName,
    })
  }

  return (
    <div className={styles.wrapper} ref={frameRef}>
      <Wrapper>
        <div className={styles.icon}>{Icon}</div>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subTitle}>{subTitle}</p>
        <div className={styles.btnContainer}>
          <ErrorBtn onClick={navigateToStartUrl} fullWidth>
            {btnText}
          </ErrorBtn>
        </div>
      </Wrapper>
    </div>
  )
}

export default ErrorResponse
